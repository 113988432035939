<template>
   <h1 v-if="$auth0.error">{{ $auth0.error.message }}</h1>
   <h5 v-else>Please wait ...</h5>
</template>
<script>
export default {
   name: 'CallBack',
   created() {
      // this.$auth0.handleRedirectCallback();
   },
};
</script>
